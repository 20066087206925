<template>
  <v-card>
    <v-container class="px-5 p-4">
      <v-card-title class="pt-0 px-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="py-0 px-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
          class="px-0 py-0"
        >
          <v-row>
            <v-col cols="12" md="8" class="py-0">
              <v-text-field
                v-model.trim="label"
                type="text"
                label="Etiqueta (*)"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(label),
                    rules.maxLength(label, 50)
                  ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <v-switch
                class="mt-0"
                v-model="requerido"
                label="Obligatorio"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8" class="py-0">
              <v-text-field
                v-model.trim="textoAyuda"
                type="text"
                label="Texto de ayuda"
                dense
                outlined
                :rules="[rules.maxLength(textoAyuda, 50)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model.trim="orden"
                type="text"
                label="N° orden"
                persistent-hint
                dense
                outlined
                v-mask="'####'"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-0">
              <v-select
                v-model="tipoSelected"
                :items="tipos"
                label="Tipo de dato (*)"
                item-text="value"
                :rules="rules.required"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>

            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-if="tipoSelected != null && tipoSelected == 'text'"
                v-model.trim="numeroLineas"
                type="text"
                label="Renglones"
                dense
                hint="Para área de texto"
                persistent-hint
                outlined
                v-mask="'#'"
              >
              </v-text-field>
              <v-text-field
                v-if="tipoSelected != null && tipoSelected == 'date'"
                v-model.trim="diasLimiteDesdeFecha"
                type="text"
                label="N° días previos"
                dense
                outlined
                v-mask="'###'"
              >
                <template v-slot:append-outer>
                  <v-tooltip
                    right
                    max-width="20rem"
                    v-if="tipoSelected != null && tipoSelected == 'date'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="1.4rem"
                        color="primary"
                        class="pl-1"
                        v-on="on"
                        v-bind="attrs"
                      >
                        {{ infoIcon }}</v-icon
                      >
                    </template>
                    <span
                      >Días previos a la fecha de carga de trámite que permitirá
                      ingresar.</span
                    >
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-if="tipoSelected != null && tipoSelected == 'text'"
                v-model.trim="limiteCaracteres"
                type="text"
                label="Caracteres"
                hint="Límite de caracteres"
                persistent-hint
                dense
                outlined
                v-mask="'####'"
              ></v-text-field>
              <v-text-field
                v-if="tipoSelected != null && tipoSelected == 'date'"
                v-model.trim="diasLimiteHastaFecha"
                type="text"
                label="N° días post."
                dense
                outlined
                v-mask="'###'"
              >
                <template v-slot:append-outer>
                  <v-tooltip
                    right
                    max-width="20rem"
                    v-if="tipoSelected != null && tipoSelected == 'date'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="1.4rem"
                        color="primary"
                        class="pl-1"
                        v-on="on"
                        v-bind="attrs"
                      >
                        {{ infoIcon }}</v-icon
                      >
                    </template>
                    <span
                      >Días posteriores a la fecha de carga de trámite que
                      permitirá ingresar.</span
                    >
                  </v-tooltip>
                </template></v-text-field
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <span> (*) Campos requeridos</span>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || loadingBtn"
          form="form"
          :loading="loadingBtn"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditConfig",
  directives: { mask },
  components: { ConfirmDialog },
  props: {
    itemConfig: {
      type: Object
    },
    idExpediente: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    isFormValid: false,
    infoIcon: enums.icons.SNB_INFO,
    tipos: [
      { id: "text", value: "Texto" },
      { id: "date", value: "Fecha" }
    ],
    tipoSelected: null,
    label: "",
    loadingBtn: false,
    requerido: false,
    formEditTitle: "Editar dato adicional",
    newTitle: "Nuevo dato adicional",
    rules: rules,
    openConfirmDialog: false,
    textConfirmDialog: "",
    orden: null,
    numeroLineas: null,
    textoAyuda: null,
    diasLimiteDesdeFecha: null,
    diasLimiteHastaFecha: null,
    limiteCaracteres: null
  }),
  created() {
    if (this.itemConfig) {
      this.setConfig();
    } else {
      this.newConfig();
    }
  },
  methods: {
    ...mapActions({
      saveAdicExpedientes: "configuracionME/saveAdicExpedientes",
      setAlert: "user/setAlert"
    }),
    async setConfig() {
      this.label = this.itemConfig.label;
      this.tipoSelected = this.itemConfig.tipoDato;
      this.requerido = this.itemConfig.requerido;
      this.orden = this.itemConfig.orden;
      this.numeroLineas = this.itemConfig.numeroLineas;
      this.textoAyuda = this.itemConfig.textoAyuda;
      this.diasLimiteDesdeFecha = this.itemConfig.diasLimiteDesdeFecha;
      this.diasLimiteHastaFecha = this.itemConfig.diasLimiteHastaFecha;
      this.limiteCaracteres = this.itemConfig.limiteCaracteres;
    },
    async newConfig() {
      this.formEditTitle = this.newTitle;
      this.idExpediente = this.idExpediente;
    },
    async canSave() {
      this.loadingBtn = true;
      const data = {
        tipoExpId: this.idExpediente,
        tramitesDatosAdicId: this.itemConfig
          ? this.itemConfig.appTramitesDatosAdicId
          : 0,
        label: this.label,
        tipoDato: this.tipoSelected,
        requerido: this.requerido,
        orden: this.orden,
        numeroLineas: this.tipoSelected == "date" ? null : this.numeroLineas,
        textoAyuda: this.textoAyuda,
        diasLimiteDesdeFecha:
          this.tipoSelected == "date" ? this.diasLimiteDesdeFecha : null,
        diasLimiteHastaFecha:
          this.tipoSelected == "date" ? this.diasLimiteHastaFecha : null,
        limiteCaracteres:
          this.tipoSelected == "date" ? null : this.limiteCaracteres
      };
      const res = await this.saveAdicExpedientes(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.loadingBtn = false;
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
<style scoped>
.repeat input {
  border-color: red;
}
.repeat label {
  color: red;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
