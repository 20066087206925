<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />

        <v-card>
          <v-container>
            <v-card-text class="py-0">
              <v-form
                v-model="isConfigFormValid"
                ref="config-usuario-form"
                form="config-usuario-form"
                id="config-usuario-form"
                @submit.prevent="save()"
              >
                <v-row>
                  <v-col cols="12" md="12" class="py-0 px-0">
                    <v-card-title>
                      <span class="primary--text">Archivos adjuntos</span>
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" md="12" class="py-0 px-0">
                    <v-textarea
                      v-model.trim="textoAyuda"
                      outlined
                      auto-grow
                      rows="1"
                      dense
                      :rules="[
                        rules.requiredTrim(textoAyuda),
                        rules.maxLength(textoAyuda, 100)
                      ]"
                      :disabled="!canConfig"
                      persistent-hint
                      label="Texto de ayuda para adjuntos"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" md="2" class="py-0 px-0 ">
                    <v-text-field
                      v-model="cantAdjuntos"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      label="Cantidad"
                      :rules="[rules.minMaxNumber(cantAdjuntos, 0, 99)]"
                      outlined
                      dense
                      :disabled="!canConfig"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="pa-0 ml-3 mt-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="primary">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span>
                        Indicar cantidad mínima de adjuntos.
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4" md="2" class="py-0 px-0 ">
                    <v-text-field
                      v-model="cantAdjuntosRequeridos"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      label="Adjuntos requeridos"
                      :rules="
                        cantAdjuntosRequeridos
                          ? [
                              parseInt(cantAdjuntos) >=
                                parseInt(cantAdjuntosRequeridos) ||
                                'Cantidad de adjuntos menor a los requeridos'
                            ]
                          : []
                      "
                      outlined
                      dense
                      :disabled="!canConfig"
                      hint="Dejar en blanco para requerir todos"
                      persistent-hint
                      aria-autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-btn
                    type="submit"
                    :disabled="!isConfigFormValid || !canConfig"
                    :loading="btnIsLoading"
                    form="config-usuario-form"
                    class="py-0 mr-5"
                    color="primary"
                  >
                    Guardar
                  </v-btn>
                </v-row>
              </v-form>
            </v-card-text>
          </v-container>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="itemsDatosAdicionales"
          class="elevation-1 mt-3"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:[`item.requerido`]="{ item }">
            <v-icon
              v-if="item.requerido"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.tipoDato`]="{ item }">
            <template v-if="item.tipoDato === 'date'">
              Fecha
            </template>
            <template v-if="item.tipoDato === 'text'">
              Texto
            </template>
          </template>
          <template v-slot:top>
            <v-col cols="12" md="12" class="py-0 px-0">
              <v-card-title>
                <span class="pl-4 primary--text">Datos adicionales</span>
              </v-card-title>
            </v-col>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar dato adicional</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteConfig(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar dato adicional</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog
          v-if="openModalEdit"
          v-model="openModalEdit"
          max-width="40%"
          @keydown.esc="closeAndReload"
          persistent
        >
          <EditConfig
            :itemConfig="itemConfig"
            :idExpediente="idExpediente"
            @closeAndReload="closeAndReload"
          />
        </v-dialog>
        <DeleteDialog
          :titleProp="titleDelete"
          :isLoading="loadingBtnDelete"
          :openDelete.sync="showDeleteModal"
          @onDeleteItem="confirmDelete()"
        />
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModalMercadoPago from "@/components/modules/appBenef/configuracion/MercadoPagoModal";
//import SIROBotonDePagoModal from "@/components/modules/appBenef/configuracion/SIROBotonDePagoModal";
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import rules from "@/utils/helpers/rules";
import EditConfig from "@/components/modules/appBenef/configuracion/EditConfig";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    Ayuda,
    EditConfig,
    ModalMercadoPago
  },
  name: "ConfigCargaTramites",
  data() {
    return {
      cantAdjuntos: null,
      cantAdjuntosRequeridos: null,
      showExpand: false,
      isConfigFormValid: false,
      btnIsLoading: false,
      textoAyuda: "",
      rules: rules,
      loadingBtnDelete: false,
      checkIcon: enums.icons.CHECK_OUTLINE,
      optionCode: enums.webSiteOptions.CONFIG_CARGA_TRAM,
      // title: `Configuración de plantilla de carga de trámites de ${this.tituloPlantilla}`,
      routeToGo: "PlantillaCargaTramites",
      headers: [
        {
          text: "Orden",
          value: "orden",
          sortable: false
        },
        {
          text: "Etiqueta",
          sortable: false,
          value: "label"
        },
        {
          text: "Tipo de dato",
          align: "left",
          value: "tipoDato",
          sortable: false
        },
        {
          text: "Obligatorio",
          align: "center",
          value: "requerido",
          sortable: false
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end"
        }
      ],
      openModalEdit: false,
      searchIcon: enums.icons.SEARCH,
      search: "",
      isLoading: false,
      allowedActions: null,
      canEdit: false,
      canConfig: false,
      canDelete: false,
      showHelp: false,
      canCreate: false,
      infoIcon: enums.icons.SNB_INFO,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      titleDelete: "¿Eliminar registro?",
      showDeleteModal: false,
      idExpediente: null,
      idExpediente: null,
      itemsDatosAdicionales: [],
      itemDatosExp: [],
      tituloPlantilla: ""
    };
  },
  async created() {
    this.itemDatosExp = this.$route.params.idTramite;
    this.tituloPlantilla = this.itemDatosExp.mTipoExpNombre;
    this.title = `Plantilla para carga de trámites de ${this.tituloPlantilla}`;
    if (this.itemDatosExp) {
      this.textoAyuda = this.itemDatosExp.adjuntosAyuda;
      this.cantAdjuntos = this.itemDatosExp.adjuntosCant;
      this.cantAdjuntosRequeridos = this.itemDatosExp.adjuntosCantRequeridos;
    }
    this.idExpediente = this.$route.params.idTramite.tipoExpId;
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setPermisos();
    this.getDatosAdic();
  },
  // mounted() {
  //   this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  //   this.setPermisos();
  // },
  methods: {
    ...mapActions({
      saveTramiteAdjuntoExpedientes:
        "configuracionME/saveTramiteAdjuntoExpedientes",
      deleteDatoAdicional: "configuracionME/deleteDatoAdicional",
      traerDatosAdicionalesTramites:
        "configuracionME/traerDatosAdicionalesTramites",
      setAlert: "user/setAlert"
    }),
    async getDatosAdic() {
      const response = await this.traerDatosAdicionalesTramites(
        this.idExpediente
      );
      this.itemsDatosAdicionales = response;
    },
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.CONFIGURAR_CARGA_TRAMITES:
            this.canConfig = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVA_CONFIG_CARGA:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_CONFIG_CARGA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_CONFIG_CARGA:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async save() {
      this.btnIsLoading = true;
      const data = {
        tipoExpId: this.idExpediente ? this.idExpediente : null,
        adjuntosCant: !this.cantAdjuntos ? null : this.cantAdjuntos,
        adjuntosCantRequeridos: this.cantAdjuntosRequeridos,
        adjuntosAyuda: this.textoAyuda
      };
      try {
        const res = await this.saveTramiteAdjuntoExpedientes(data);
        if (res.status === 200) {
          this.btnIsLoading = false;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeModal();
        }
      } catch {
        this.btnIsLoading = false;
      }
    },
    openModal(item) {
      this.itemConfig = item;
      this.openModalEdit = true;
      this.idExpediente = this.idExpediente;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.getDatosAdic();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    deleteConfig(item) {
      this.showDeleteModal = true;
      this.idToAnular = item.appTramitesDatosAdicId;
    },
    async confirmDelete() {
      try {
        this.loadingBtnDelete = true;
        const response = await this.deleteDatoAdicional({
          appTramitesDatosAdicId: this.idToAnular
        });
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({
            type: "success",
            message: "Eliminado con éxito."
          });
          this.loadingBtnDelete = false;
          this.getDatosAdic();
        }
      } catch (error) {
        this.showDeleteModal = false;
      }
      this.loadingBtnDelete = false;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input,
::v-deep .v-textarea input,
::v-deep .v-textarea textarea:disabled {
  color: rgb(56, 56, 56) !important;
  cursor: context-menu !important;
}

::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select__selection--disabled input {
  color: black !important;
}
</style>
