var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pb-3",attrs:{"title":_vm.title}}),_c('v-card',[_c('v-container',[_c('v-card-text',{staticClass:"py-0"},[_c('v-form',{ref:"config-usuario-form",attrs:{"form":"config-usuario-form","id":"config-usuario-form"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.isConfigFormValid),callback:function ($$v) {_vm.isConfigFormValid=$$v},expression:"isConfigFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":"12"}},[_c('v-card-title',[_c('span',{staticClass:"primary--text"},[_vm._v("Archivos adjuntos")])])],1),_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","auto-grow":"","rows":"1","dense":"","rules":[
                      _vm.rules.requiredTrim(_vm.textoAyuda),
                      _vm.rules.maxLength(_vm.textoAyuda, 100)
                    ],"disabled":!_vm.canConfig,"persistent-hint":"","label":"Texto de ayuda para adjuntos"},model:{value:(_vm.textoAyuda),callback:function ($$v) {_vm.textoAyuda=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"textoAyuda"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 px-0 ",attrs:{"cols":"4","md":"2"}},[_c('v-text-field',{attrs:{"onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","label":"Cantidad","rules":[_vm.rules.minMaxNumber(_vm.cantAdjuntos, 0, 99)],"outlined":"","dense":"","disabled":!_vm.canConfig,"persistent-hint":""},model:{value:(_vm.cantAdjuntos),callback:function ($$v) {_vm.cantAdjuntos=$$v},expression:"cantAdjuntos"}})],1),_c('v-col',{staticClass:"pa-0 ml-3 mt-2",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}])},[_c('span',[_vm._v(" Indicar cantidad mínima de adjuntos. ")])])],1),_c('v-col',{staticClass:"py-0 px-0 ",attrs:{"cols":"4","md":"2"}},[_c('v-text-field',{attrs:{"onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","label":"Adjuntos requeridos","rules":_vm.cantAdjuntosRequeridos
                        ? [
                            parseInt(_vm.cantAdjuntos) >=
                              parseInt(_vm.cantAdjuntosRequeridos) ||
                              'Cantidad de adjuntos menor a los requeridos'
                          ]
                        : [],"outlined":"","dense":"","disabled":!_vm.canConfig,"hint":"Dejar en blanco para requerir todos","persistent-hint":"","aria-autocomplete":"off"},model:{value:(_vm.cantAdjuntosRequeridos),callback:function ($$v) {_vm.cantAdjuntosRequeridos=$$v},expression:"cantAdjuntosRequeridos"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"py-0 mr-5",attrs:{"type":"submit","disabled":!_vm.isConfigFormValid || !_vm.canConfig,"loading":_vm.btnIsLoading,"form":"config-usuario-form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"headers":_vm.headers,"items":_vm.itemsDatosAdicionales,"search":_vm.search,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.requerido",fn:function(ref){
                        var item = ref.item;
return [(item.requerido)?_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.tipoDato",fn:function(ref){
                        var item = ref.item;
return [(item.tipoDato === 'date')?[_vm._v(" Fecha ")]:_vm._e(),(item.tipoDato === 'text')?[_vm._v(" Texto ")]:_vm._e()]}},{key:"top",fn:function(){return [_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":"12"}},[_c('v-card-title',[_c('span',{staticClass:"pl-4 primary--text"},[_vm._v("Datos adicionales")])])],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar dato adicional")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteConfig(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar dato adicional")])]):_vm._e()]}}],null,true)}),(_vm.openModalEdit)?_c('v-dialog',{attrs:{"max-width":"40%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalEdit),callback:function ($$v) {_vm.openModalEdit=$$v},expression:"openModalEdit"}},[_c('EditConfig',{attrs:{"itemConfig":_vm.itemConfig,"idExpediente":_vm.idExpediente},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"isLoading":_vm.loadingBtnDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1),_c('v-col',{staticClass:"to-right pt-7 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }